import React from 'react';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import moment from 'moment';

import data from '@@ROOT/src/data.json';


const Bio = (/* props */) => {
	return (
		<Box p={6}>
			<Grid container justify="center" alignItems="center" spacing={4}>
				<Grid item sm={3} lg={2}>
					<img src="/img/bio.jpg" alt="bio" style={{ margin: '0 auto', maxWidth: '100%' }} />
				</Grid>
				<Grid item sm={5} lg={6}>
					<Typography component="h2" variant="h4" gutterBottom>
						{data.about.heading}
					</Typography>
					<p>{data.about.bio}</p>
				</Grid>

				<Grid item xs={12} sm={8}>

					<Typography component="h2" variant="h4" gutterBottom>
						Exhibits
					</Typography>

					<ul>
						{data.exhibits.map((exhibit) => (
							<li key={exhibit.id}>
								{exhibit.url && (
									<span>
										<Link href={exhibit.url} color="inherit" target="_blank" rel="nofollow noopener">
											{exhibit.title}
										</Link>
										, {exhibit.location}&nbsp;
									</span>
								)}
								{!exhibit.url && (
									<span>
										{exhibit.title}, {exhibit.location}&nbsp;
									</span>
								)}
								{exhibit.city}, {exhibit.state},&nbsp;
								{moment(exhibit.month, 'M').format('MMMM')} {exhibit.year}

							</li>
						))}
					</ul>

					<Typography component="h2" variant="h4" gutterBottom>
						Press
					</Typography>

					<ul>
						{data.press.map((press) => (
							<li key={press.id}>
								<Link href={press.link} color="inherit" target="_blank" rel="nofollow noopener">
									{press.title}
								</Link>
							</li>
						))}
					</ul>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Bio;
