import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { Copyright } from './Footer.styles';


const Footer = () => {
	return (
		<Box my={5} component="footer">
			<Grid container>
				<Grid item xs>
					<Copyright>Copyright &copy; {new Date().getFullYear()} Carol Langley. All rights reserved.</Copyright>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Footer;
