import React from 'react';
import { Box, Grid } from '@material-ui/core';


const Home = (/* props */) => {
	return (
		<Box p={5}>
			<Grid container justify="center" alignItems="center" spacing={4}>
				<img src="/img/self-portrait-ochre.jpg" alt="self portrait" style={{ maxHeight: '75vh' }} />
			</Grid>
		</Box>
	);
};

export default Home;
