import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
} from 'react-router-dom';

import { AppBar, Button, Grid, Toolbar, Container, CssBaseline } from '@material-ui/core';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

import GlobalStyle from '@@Theme/components/GlobalStyle';
import theme, { colors } from '@@Theme';

import Bio from '@@Components/Bio';
import Collection from '@@Components/Collection';
import Home from '@@Components/Home';
import Footer from '@@Components/Footer';

import config from '@@Config';


class App extends PureComponent {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		console.info('[App] componentDidMount()');
	}

	render() {
		return (
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Router>
						<CssBaseline />
						<GlobalStyle />

						<Helmet
							titleTemplate={`%s - ${config.basePageTitle}`}
							defaultTitle={config.basePageTitle}
						>
							<meta name="theme-color" content={colors.primary['500']} />
						</Helmet>

						<Container maxWidth={false} disableGutters>

							<AppBar position="static" elevation={0} square style={{ backgroundColor: 'white' }}>
								<Toolbar>
									<Grid container justify="center" alignItems="center">
										<Grid item>
											<Button component={Link} variant="text" to="/">Carol Langley</Button>
											<Button component={Link} style={{ fontWeight: 'normal' }} variant="text" to="/bio">Bio</Button>
											<Button component={Link} style={{ fontWeight: 'normal' }} variant="text" to="/collections/dogs-and-their-persons">Dogs and Their Persons</Button>
											<Button component={Link} style={{ fontWeight: 'normal' }} variant="text" to="/collections/leaving-kansas">Leaving Kansas</Button>
											<Button component={Link} style={{ fontWeight: 'normal' }} variant="text" to="/collections/encaustics">Encaustics and Cold Wax</Button>
											<Button component={Link} style={{ fontWeight: 'normal' }} variant="text" to="/collections/drawings">Drawings</Button>
											<Button component={Link} style={{ fontWeight: 'normal' }} variant="text" to="/collections/paintings">Paintings</Button>
										</Grid>
									</Grid>
								</Toolbar>
							</AppBar>

							<Switch>
								<Route
									exact
									path="/"
									render={props => <Home {...props} />}
								/>
								<Route
									exact
									path="/bio"
									render={props => <Bio {...props} />}
								/>
								<Route
									exact
									path="/collections/dogs-and-their-persons"
									render={props => <Collection {...props} id={1} />}
								/>
								<Route
									exact
									path="/collections/leaving-kansas"
									render={props => <Collection {...props} id={2} />}
								/>
								<Route
									exact
									path="/collections/encaustics"
									render={props => <Collection {...props} id={3} />}
								/>
								<Route
									exact
									path="/collections/drawings"
									render={props => <Collection {...props} id={4} />}
								/>
								<Route
									exact
									path="/collections/paintings"
									render={props => <Collection {...props} id={5} />}
								/>
							</Switch>

							<Footer />

						</Container>

					</Router>
				</ThemeProvider>
			</StylesProvider>
		);
	}
}

export default App;
