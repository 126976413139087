import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
	body {
		background-color: #fff;
		height: 100%;
		line-height: 1.4em;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.MuiButton-label {
		.MuiSvgIcon-root {
			margin-right: 0.15em;
		}
	}

	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 2rem;

		.grid-item {
			display: grid;
			place-items: center;
			aspect-ratio: 1 / 1;

			overflow: hidden;

			&::before {
				content: "";
				display: block;
				padding-bottom: 100%;
				grid-area: 1 / 1 / 1;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				grid-area: 1 / 1 / 1;
			}
		}
	}
`;

export default GlobalStyle;
