import { createMuiTheme } from '@material-ui/core/styles';

import * as colors from './colors';
import overrides from './overrides';


const theme = createMuiTheme({
	overrides,
	colors,
	palette: {
		primary: colors.primary,
		secondary: colors.secondary,
	},
	typography: {
		fontSize: 14,
		fontFamily: [
			'Asap',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		h1: {
			fontFamily: 'Lora',
			fontSize: '2.15rem',
			letterSpacing: -0.25,
		},
		h2: {
			fontFamily: 'Lora',
			fontSize: '1.75rem',
		},
		h3: {
			fontFamily: 'Lora',
			fontSize: '1.5rem',
		},
		h4: {
			fontFamily: 'Lora',
			fontSize: '1.4rem',
		},
		h5: {
			fontFamily: 'Lora',
			fontSize: '1.2rem',
		},
		h6: {
			fontFamily: 'Lora',
			fontSize: '1rem',
		},
		button: {
			whiteSpace: 'nowrap',
			fontSize: '1em',
			fontWeight: '600',
			textTransform: 'none',
		},
		caption: {
			lineHeight: 1.25,
		},
		paper: {
			inactive: {
				backgroundColor: '#ddd',
				color: '#ccc',
			},
		},
	},
});

window.theme = theme;

export {
	colors,
};

export default theme;
