import { transparentize } from 'polished';

import colors from './colors';


const overrides = {
	MuiAppBar: {
		root: {
			paddingTop: '1em',
		},
	},
	MuiTableCell: {
		root: {
			verticalAlign: 'top',
		},
		head: {
			fontWeight: '700',
		},
	},
	MuiTableSortLabel: {
		root: {
			lineHeight: 1.25,
		},
	},
	MuiButton: {
		root: {
			// textTransform: 'uppercase',
			fontWeight: '700',
		},
		containedPrimary: {
			backgroundColor: colors.primary.main,
		},
		containedSecondary: {
			backgroundColor: colors.white,
			color: colors.primary.main,
			'&:hover': {
				backgroundColor: colors.primary.light,
			},
		},
	},
	MuiFormControl: {
		root: {
			marginBottom: '0.5em',
		},
	},
	MuiFormGroup: {
		root: {
			marginBottom: '1em',
		},
	},
	MuiFilledInput: {
		root: {
			backgroundColor: colors.grey['100'],
		},
		underline: {
			'&:before': {
				borderBottomWidth: 1,
				borderBottomColor: transparentize(0.85, colors.black),
			},
		},
	},
	MuiExpansionPanelDetails: {
		root: {
			padding: '1em 2em',
		},
	},
};

export default overrides;
