import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import colors from '@@Theme/colors';


const Copyright = styled(Typography)`
	font-size: 0.85em;
	text-align: center;
	color: ${colors.grey['600']};
`;

export {
	Copyright,
};
