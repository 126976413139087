const {
	common,
	blue,
	green,
	deepOrange,
	red,
	grey,
} = require('@material-ui/core/colors');


const primary = {
	light: '#a1a2a8',
	main: '#63666d',
	dark: '#3d4047',
	contrastText: '#fff',
};

const secondary = {
	light: '#7faebc',
	main: '#63979e',
	dark: '#1a6a7a',
	contrastText: '#fff',
};

const error = red;
const warning = deepOrange;
const info = blue;
const success = green;
const { black, white } = common;

module.exports = {
	primary,
	secondary,
	error,
	warning,
	info,
	success,
	black,
	white,
	grey,
};
