import React, { useState } from 'react';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';

import data from '../data.json';


function Item(props) {
	return (
		<Box style={{ textAlign: 'center' }}>

			<Box my={2}>
				{props.item.file && (
					<img alt={props.item.title} src={`/img/${props.item.file}`} style={{ margin: '0 auto', maxHeight: '65vh', maxWidth: '100%' }} />
				)}
			</Box>

			<Typography variant="h6">
				{props.item.title}
			</Typography>

			<Box mb={2}>
				<small>
					<span>{props.item.size}</span>
					{props.item.medium && (
						<span>&nbsp;&nbsp;|&nbsp;&nbsp;{props.item.medium}</span>
					)}
					{props.item.sale && (
						<span>&nbsp;&nbsp;|&nbsp;&nbsp;{props.item.sale}</span>
					)}
				</small>
			</Box>

		</Box>
	);
}

const Collection = (props) => {

	const [modalIsOpen, setIsOpen] = useState(false);
	const [activeItem, setActiveItem] = useState(null);
	const collection = data.collections.find((c) => c.id === props.id);

	function openModal(item) {
		setIsOpen(true);
		setActiveItem(item);
	}

	function closeModal() {
		setIsOpen(false);
	}

	function changeItemIndex(amount = 1) {
		const items = Object.values(collection.items);
		const index = items.indexOf(activeItem);
		setActiveItem(items[index + amount]);
	}

	return (
		<Box p={6}>

			<Helmet>
				<title>{collection.title}</title>
			</Helmet>

			<Grid container justify="center">
				<Grid item xs={12} sm={8}>

					<Typography variant="h2" gutterBottom>
						{collection.title}
					</Typography>

					<Typography>
						{collection.description}
					</Typography>

					<Box my={3}>
						<div className="grid">
							{collection.items.map((item, i) => (
								<div
									key={item.file}
									className="grid-item"
									title={item.title}
									onClick={() => openModal(item)}
									onKeyDown={() => openModal(item)}
									role="button"
									tabIndex={i}
								>
									<img
										alt={item.title}
										src={`/img/${item.file}`}
										style={{ margin: '0 auto', width: '100%', height: '100%', objectFit: 'cover' }}
									/>
								</div>
							))}
						</div>
					</Box>

				</Grid>
			</Grid>

			{activeItem && (
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					appElement={document.getElementById('root')}
					style={{
						overlay: {
							position: 'fixed',
							top: 0,
							left: 0,
							right: 0,
							bottom: 'auto',
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							display: 'flex',
							height: '100vh',
							alignItems: 'center',
						},
						content: {
							inset: 0,
							width: 'auto',
							maxWidth: '85vw',
							position: 'relative',
							border: '1px solid #ddd',
							background: '#fff',
							overflow: 'visible',
							WebkitOverflowScrolling: 'touch',
							margin: '0 auto',
						},
					}}
					contentLabel={activeItem.title}
				>
					<IconButton
						onClick={closeModal}
						size="small"
						title="Close"
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'absolute',
							right: '-0.75em',
							top: '-0.75em',
							border: '1px solid #eee',
							backgroundColor: '#fff',
						}}
					>
						<CloseIcon
							style={{ opacity: 0.75, margin: '0.25em' }}
							fontSize="small"
						/>
					</IconButton>

					<Item
						key={activeItem.id}
						item={activeItem}
					/>

					<Box mb={2}>
						<Grid container justify="center">
							<Grid item>
								<IconButton
									title="Previous"
									onClick={() => changeItemIndex(-1)}
									disabled={activeItem === collection.items[0]}
								>
									<ArrowBackIcon
										fontSize="small"
										style={{ opacity: 0.75 }}
									/>
								</IconButton>
							</Grid>
							<Grid item>
								<IconButton
									title="Next"
									onClick={() => changeItemIndex(1)}
									disabled={activeItem === collection.items[collection.items.length - 1]}
								>
									<ArrowForwardIcon
										fontSize="small"
										style={{ opacity: 0.75 }}
									/>
								</IconButton>
							</Grid>
						</Grid>
					</Box>

				</Modal>
			)}
		</Box>
	);
};

export default Collection;
